import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.sfgate.com/cgi-bin/blogs/green/bios"
      }}>{`Cameron Scott`}</a>{`, of the `}<a parentName="p" {...{
        "href": "http://www.sfgate.com"
      }}>{`San Francisco Chronicle`}</a>{`, writes on `}<a parentName="p" {...{
        "href": "http://www.sfgate.com/cgi-bin/blogs/green/index"
      }}>{`The Thin Green Line`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If everyone in San Francisco went paperless, in one year, we could save 546 million pounds of paper, 5.5 million gallons of water, 11 million pounds of greenhouse gases and \\$33 million.`}</p>
    </blockquote>
    <p>{`Those numbers are staggering. PayPerks helped Cameron calculate the savings from our research used to help companies calculate their cash savings and environmental impact from moving to paperless pay.`}</p>
    <p>{`You can read the whole article `}<a parentName="p" {...{
        "href": "http://www.sfgate.com/cgi-bin/blogs/green/detail?entry_id=62675&tsp=1"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      